@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  display: none;
}
.slider-video {
  display: block !important;
}
@media (max-width: 430px) {
  .video {
    width: 300px !important;
    height: 300px !important;
  }
  .slider-video {
    display: none !important;
  }
  .tw-connect-wallet,
  .tw-connected-wallet {
    padding: 9px !important;
    text-wrap: balance !important;
    /* min-height: 49px !important; */
    font-size: 12px !important;
    min-width: 60px !important;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.table {
  border-radius: 15px !important;
}
.input:read-only {
  opacity: 70%;
  background-color: white;
}
button:disabled {
  cursor: not-allowed;
  opacity: 70%;
}
